import React, { useEffect, useState } from 'react';
import { Paragraph, TextError } from '../../UI/Typography/Typography';
import ContentModal from '../../UI/modal/ContentModal.tsx';
import Checkbox from '../../UI/checkbox/Checkbox';

import teamsEmptyImg from '../../assets/icons/empty/teams_empty.png'; // Імпорт картинки
import cl from './AddToTeamsModal.module.css';

function AddToTeamsModal({ teams, modalIsOpen, setModalIsOpen, onConfirm, onClose, loading, error, ...props }) {
	const [checkedTeams, setCheckedTeams] = useState([]);

	const onCheckboxChange = (groupName) => {
		if (!checkedTeams.includes(groupName)) {
			setCheckedTeams([...checkedTeams, groupName]);
		} else {
			setCheckedTeams((old) => old.filter((team) => team !== groupName));
		}
	};

	const onClean = () => {
		setCheckedTeams([]);
	};

	const [load, setLoad] = useState(false);

	const onSend = async () => {
		setLoad(true);
		await onConfirm(checkedTeams, onClean);
		setLoad(false);
	};

	return (
		<ContentModal
			header="Add to team"
			textButton="Add"
			modalIsOpen={modalIsOpen}
			setModalIsOpen={setModalIsOpen}
			onConfirm={onSend}
			closeOnConfirm={false}
			onClose={onClose}
			loading={load}
			disabled={checkedTeams.length === 0 || loading}
		>
			<div className={cl.container}>
				<Paragraph>Teams {checkedTeams.length > 0 && `(${checkedTeams.length})`}</Paragraph>
				<div className={cl.scroll}>
					{teams?.length > 0 ? (
						teams.map((team) => (
							<div className={cl.checkbox} key={team.groupName}>
								<Checkbox
									checked={checkedTeams.includes(team.groupName)}
									onChange={() => onCheckboxChange(team.groupName)}
								>
									{team.groupName}
								</Checkbox>
							</div>
						))
					) : (
						// Відображення картинки + тексту, якщо немає команд
						<div className={cl.emptyState}>
							<img src={teamsEmptyImg} alt="No teams available" className={cl.emptyImage} />
							<Paragraph>There are no available teams yet</Paragraph>
						</div>
					)}
				</div>
				{error && <TextError style={{ textAlign: 'center' }}>{error}</TextError>}
			</div>
		</ContentModal>
	);
}

export default AddToTeamsModal;
