import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { uid } from 'uid';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import { TABLET_WIDTH, useWindowWidth } from '../../hooks/useWindowWidth';
import { activateUsers, deactivateUsers, deleteUser, getUsers } from '../../redux/slices/UsersSlice';
import { createTeam, getTeams } from '../../redux/slices/TeamsSlice';
import { addToTeam } from '../../redux/slices/TeamSettingsSlice';
import { deleteInvites, getInvites, sendInvites } from '../../redux/slices/InvitesSlice';

import InviteModal from '../../components/modal/InviteModal';
import Filter from '../../components/sidebar/Filter';
import AddToTeamsModal from '../../components/modal/AddToTeamsModal';
import CreateTeam from '../../components/modal/CreateTeam';
import InputsRow from './users/InputsRow';
import Modals from './users/Modals';
import UsersTablePR from './users/UsersTablePR';

import BackButton from '../../UI/backButton/BackButton';
import Button from '../../UI/Button/Button'
import HeaderPage from '../../UI/headerPage/HeaderPage'
import Modal from '../../UI/modal/Modal';
import Sidebar from '../../UI/sidebar/Sidebar';
import SearchInput from '../../UI/searchInput/SearchInput';
import AccessDenialOverlay from '../../UI/accessDenialOverlay/AccessDenialOverlay';
import TabsPrime from '../../UI/tabs/TabsPrime.tsx';

import cl from '../../styles/pages/team/Users.module.css'
import MobileSearchInputWithClose from '../../UI/searchInput/MobileSearchInputWithClose';

const options = [
	{ value: 'all', label: 'All roles' },
	{ value: 'ADMIN', label: 'Admin' },
	{ value: 'OWNER', label: 'Owner' },
	{ value: 'ENHANCED', label: 'Enhanced' },
]

const mobOptions = [
	{ value: 'ADMIN', label: 'Admin' },
	{ value: 'OWNER', label: 'Owner' },
	{ value: 'ENHANCED', label: 'Enhanced' },
]

const activeActions = [
	{ 'id': uid(8), 'icon': 'icon-User_deactivate', tooltip: 'Deactivate User', 'action': 'deactivate', 'header': 'Deactivate' },
	{ 'id': uid(8), 'icon': 'icon-User-team', tooltip: 'Add to team', 'action': 'add', 'header': 'Add to team' },
	{ 'id': uid(8), 'icon': 'icon-User-new-team', tooltip: 'Create team', 'action': 'create', 'header': 'Create team' },
]

const deactiveActions = [
	{ 'id': uid(8), 'icon': 'icon-user_reactivate', tooltip: 'Reactivate', 'action': 'reactivate', 'header': 'Reactivate' }, //add needed icon
	{ 'id': uid(8), 'icon': 'icon-delete', tooltip: 'Delete', 'action': 'delete', 'header': 'Delete' },
]

const inviteActions = [
	{ 'id': uid(8), 'icon': 'icon-email_revoke', tooltip: 'Revoke', 'action': 'revoke', 'header': 'Revoke' }, //add needed icon
	{ 'id': uid(8), 'icon': 'icon-email_resend', tooltip: 'Resend', 'action': 'resend', 'header': 'Resend' },
]

function UsersPage() {
	const winWidth = useWindowWidth();

	const comID = useSelector(state => state.userInfo.info?.company)
	const userID = useSelector(state => state.userInfo.info?.id)
	const verification = useSelector((state) => state.company?.info?.confirmed_email)
	const role = useSelector(state => state.userInfo.info?.role)
	const companyName = useSelector(state => state.company.info?.companyName)
	const { users, loading: usersLoading, error } = useSelector(state => state.users);
	const teams = useSelector(state => state.teams?.teams)
	const { invites, loading: invitesLoading } = useSelector(state => state.invites);

	const [activeUsers, setActiveUsers] = useState(null)
	const [usersDeactiv, setUsersDeactiv] = useState(null)
	const [invitesList, setInvitesList] = useState(null)

	const dispatch = useDispatch()

	const [searchInput, setSearchInput] = useState('')
	const [selectFilter, setSelectFilter] = useState(options[0].value)
	const [multipleFilter, setMultipleFilter] = useState([])

	const getUsersList = async () => {
		const res = await dispatch(getUsers(comID))
			;
		if (res.payload.status !== 'success') {
			toast.error('Something went wrong. Users were not loaded. Try again later.')
		}
		setLoading(false)
	}

	const getInvitesList = async () => {
		const res = await dispatch(getInvites())
	}

	const getTeamsList = async () => {
		const res = await dispatch(getTeams(comID))
		//add errors
	}

	useEffect(() => {
		if (comID !== undefined) {
			getUsersList()
		}
	}, [comID])

	useEffect(() => {
		if (comID !== undefined && teams !== undefined) {
			getTeamsList()
		}
	}, [comID])

	useEffect(() => {
		getInvitesList()
	}, [])


	useEffect(() => {
		if (users !== undefined && users !== null) {
			setActiveUsers(users.filter(user => user.status === 'ACTIVE'))
			const deactiv = users.filter(user => user.status !== 'ACTIVE')
			setUsersDeactiv(deactiv)
		}
	}, [users])

	useEffect(() => {
		if (invites !== undefined && invites !== null) {
			setInvitesList(invites)
		}
	}, [invites])

	const onChangeSelectFilter = (value) => {
		setSelectFilter(value.value)
	}

	const onSearchChange = (e) => {
		setSearchInput(e.target.value)
	}

	const [inviteModal, setInviteModal] = useState(false)
	const [createTeamModal, setCreateTeamModal] = useState(false)
	const [addToTeamsModal, setAddToTeamsModal] = useState(false)
	const [loading, setLoading] = useState(true)
	const [createErr, setCreateErr] = useState('')

	const [activeModal, setActiveModal] = useState('')

	const [checkedActive, setCheckedActive] = useState([])
	const [checkedDeactive, setCheckedDeactive] = useState([])
	const [checkedInvites, setCheckedInvites] = useState([])

	const setSelectAction = async (action, users) => {
		//await setCheckedIds(ids)

		switch (action) {
			case 'deactivate':
				setActiveModal('deactivate')
				break;
			case 'reactivate':
				setActiveModal('reactivate')
				break;
			case 'delete':
				setActiveModal('delete')
				break;
			case 'add':
				setAddToTeamsModal(true)
				break;
			case 'create':
				setCreateTeamModal(true)
				break;
			case 'revoke':
				setActiveModal('revoke')
				break;
			case 'resend':
				setActiveModal('resend')
				break;
			default:
				break;
		}
	}

	const onAddToTeam = async (groupName, ids, onClean) => {

		const userArr = ids
		const res = await dispatch(addToTeam({ groupName, userArr }))
		if (res.payload.status === 'success') {
			toast.success('Team created')
			setCheckedActive([])
			setCreateTeamModal(false)
			onClean()
		} else {
			toast.success('Team created')
			toast.error('Users were not added to the team')
		}
	}

	const onAddToTeams = async (teams, onClean) => {
		let success = []
		let error = []
		let userArr = checkedActive.map(user => user.id || user)

		for (let i = 0; i < teams.length; i++) {
			const res = await dispatch(addToTeam({ groupName: teams[i], userArr }))
			if (res.payload.status === 'success') {
				success.push(teams[i])
			} else {
				error.push(teams[i])
			}
		}
		if (success.length === teams.length) {
			setCheckedActive([])
			toast.success('Users added to teams')
			setAddToTeamsModal(false)
			onClean()
		}
		if (error.length !== 0) {
			toast.error('Users were not added to some teams')
		}
		getUsersList()

	}

	const onCreateTeam = async (teamName, ids, onClean) => {
		setLoading(true)
		const res = await dispatch(createTeam(teamName))
		if (res.payload.status === 'success') {
			await onAddToTeam(teamName, ids, onClean)
		} else if (res.payload === 401) {
			setCreateErr('Team already exist')
		} else if (res.payload === 402) {
			setCreateErr('Permission denied')
		} else {
			setCreateErr('Something went wrong')
		}
		getUsersList()
		setLoading(false)
	}

	const setCheckedIdsFunc = (ids, type) => {
		if (type === 'active') {
			setCheckedActive(ids)
		} else if (type === 'deactive') {
			setCheckedDeactive(ids)
		} else if (type === 'invite') {
			setCheckedInvites(ids)
		}
	}


	const onDeactivate = async () => {
		setLoading(true)
		let usersActive = checkedActive.filter(user => user.role !== 'OWNER')

		if (usersActive.length < checkedActive.length) {
			toast.warn('You cannot delete users with an owner role')
		}
		if (usersActive.length === 0) {
			toast.warn('You cannot delete users with an owner role')
			setCheckedActive([])
			setLoading(false)
			return
		}
		let success = []
		let error = []

		for (let i = 0; i < usersActive.length; i++) {
			const res = await dispatch(deactivateUsers({ userId: usersActive[i].id, email: usersActive[i].email }))
			if (res.payload?.data?.status === 'success') {
				checkedActive[0].id ? success.push(usersActive[i])
					: success.push(usersActive[i].id)
			} else if (res.payload === 402) {
				toast.error('Permission denied')
				checkedActive[0].id ? error.push(usersActive[i])
					: error.push(usersActive[i].id)
			} else {
				toast.error('Something went wrong')
				checkedActive[0].id ? error.push(usersActive[i])
					: error.push(usersActive[i].id)
			}
		}
		if (success.length === usersActive.length) {
			setCheckedActive([])
			toast.success('Users deactivated')
		}
		if (error.length !== 0) {
			setCheckedActive([...error])
			toast.error('Checked users were not deactivated')
		}

		setLoading(false)
	}

	const onActivate = async () => {
		setLoading(true)
		let usersDeactive = checkedDeactive
		let success = []
		let error = []
		for (let i = 0; i < usersDeactive.length; i++) {
			const res = await dispatch(activateUsers({ userId: usersDeactive[i].id, email: usersDeactive[i].email }))

			if (res.payload?.data?.status === 'success') {
				success.push(usersDeactive[i].id)
			} else if (res.payload === 402) {
				toast.error('Upgrade your plan to add another user due to insufficient licenses')
				error.push(usersDeactive[i].id)
			} else {
				toast.error('Something went wrong')
				error.push(usersDeactive[i].id)
			}
		}
		if (success.length === usersDeactive.length) {
			setCheckedDeactive([])
			toast.success('Users activated')
		}
		if (error.length !== 0) {
			setCheckedDeactive([[...error]])
			toast.error('Checked users were not activated')
		}

		setLoading(false)
	}

	const onInvite = async () => {
		setLoading(true)
		let checkedIn = checkedInvites
		const res = await Promise.all(checkedIn.map((invite) => dispatch(sendInvites({ email: invite.email, role: invite.role, companyName }))))
		await res.forEach(result => {

			if (result.payload?.status === 'success') {
				checkedIn = checkedIn.filter(invite => invite.email !== result.meta.arg.email)
				toast.success(`Invite sent to ${result.meta.arg.email}`)
			} else {
				switch (result.payload) {
					case 402:
						toast.error('Permission denied')
						break;
					case 401:
						toast.error('User already exists')
						break;
					case 403:
						toast.error('Email is not valid')
						break;
					case 404:
						toast.error('User not found')
						break;
					case 400:
						toast.error('Something went wrong')
						break;
					default:
						toast.error('Something went wrong')
						break;
				}
			}
		})
		checkedIn = checkedIn.map(invite => invite.id)
		setCheckedInvites(checkedIn)
		getInvitesList()
		setLoading(false)
	}

	const onDeleteInvite = async () => {
		setLoading(true)
		let success = []
		let error = []
		let checkedIn = checkedInvites
		const res = await Promise.all(checkedIn.map((invite) => dispatch(deleteInvites({ regtoken: invite.regtoken, role }))))
		await res.forEach(result => {
			if (result.payload?.status === 'success') {
				success.push(result.meta.arg.email)
			} else {
				error.push(result.payload)
				toast.error(`Invite not sent to ${result.meta.arg.email}`)
				switch (result.payload) {
					case 402:
						toast.error('Permission denied')
						break;
					case 401:
						toast.error('User already exists')
						break;
					case 403:
						toast.error('Email is not valid')
						break;
					case 404:
						toast.error('User not found')
						break;
					case 400:
						toast.error('Something went wrong')
						break;
					default:
						toast.error('Something went wrong')
						break;
				}
			}
		})
		if (error.length === 0) {
			toast.success('Invites revoked')
			setCheckedInvites([])
			getInvitesList()
		} else {
			toast.error('Some invites were not revoked')
		}
		setLoading(false)
	}

	const onDelete = async () => {
		setLoading(true)
		let usersForDelete = checkedDeactive
		let success = []
		let error = []
		for (let i = 0; i < usersForDelete.length; i++) {
			const res = await dispatch(deleteUser({ userId: usersForDelete[i].id, email: usersForDelete[i].email }))
			if (res.payload?.data?.status === 'success') {
				success.push(usersForDelete[i].id)
			} else if (res.payload === 402) {
				toast.error('Permission denied')
				error.push(usersForDelete[i].id)
			} else {
				toast.error('Something went wrong')
				error.push(usersForDelete[i].id)
			}
		}
		if (success.length === checkedDeactive.length) {
			setCheckedDeactive([])
			toast.success('Users deleted')
		}
		if (error.length !== 0) {
			setCheckedDeactive([...error])
			toast.error('Checked users were not deleted')
		}
		setLoading(false)
	}



	const onConfirm = () => {
		if (activeModal === 'deactivate') {
			onDeactivate()
		} else if (activeModal === 'reactivate') {
			onActivate()
		} else if (activeModal === 'resend') {
			onInvite()
		} else if (activeModal === 'revoke') {
			onDeleteInvite()
		} else if (activeModal === 'delete') {
			onDelete()
		}
		setActiveModal('')
	}
	const onModalClose = () => {
		setActiveModal('')
	}


	const [activeTab, setActiveTab] = useState(0)

	const links = ['Active', 'Deactivated', 'Invites']
	const tabs = [

		<UsersTablePR
			data={activeUsers}
			activeActions={activeActions}
			searchFilter={searchInput}
			selectFilter={selectFilter}
			checked={checkedActive}
			setChecked={setCheckedActive}
			setSelectAction={setSelectAction}
			loading={usersLoading}
			multipleFilter={multipleFilter}
		/>,
		<UsersTablePR
			data={usersDeactiv}
			activeActions={deactiveActions}
			searchFilter={searchInput}
			selectFilter={selectFilter}
			checked={checkedDeactive}
			setChecked={setCheckedDeactive}
			setSelectAction={setSelectAction}
			loading={usersLoading}
			multipleFilter={multipleFilter}
		/>,
		<UsersTablePR
			data={invitesList}
			checked={checkedInvites}
			setChecked={setCheckedInvites}
			activeActions={inviteActions}
			searchFilter={searchInput}
			selectFilter={selectFilter}
			setSelectAction={setSelectAction}
			multipleFilter={multipleFilter}
			type='invites'
			loading={invitesLoading} />,
		//<UsersTable verification={verification} loading={usersLoading || loading} setAllChecked={setAllCheckedIdByTab} checked={checkedIds.deactive} setChecked={setCheckedIdByTab} setSelectAction={setSelectAction} data={usersDeactiv} icons={deactiveActions} searchFilter={searchInput} selectFilter={selectFilter} />,
		//<UsersTable verification={verification} loading={invitesLoading || loading} setAllChecked={setAllCheckedIdByTab} checked={checkedIds.invites} setChecked={setCheckedIdByTab} type='invite' setSelectAction={setSelectAction} data={invites} icons={inviteActiove} searchFilter={searchInput} selectFilter={selectFilter} />
	]


	const commonComponents = <>
		<Modal style={{ maxWidth: 440, width: '90%' }} modalIsOpen={inviteModal} setIsOpen={setInviteModal} >
			<InviteModal callback={getInvitesList} setModalIsOpen={setInviteModal} header='Invite users' textButton='Send invitation' loading={loading} />
		</Modal>
		{createTeamModal && <Modal style={{ maxWidth: 440, width: '90%' }} modalIsOpen={createTeamModal} setIsOpen={setCreateTeamModal} >
			<CreateTeam
				checkedIds={checkedActive}
				setCheckedIds={setCheckedIdsFunc}
				onConfirm={onCreateTeam}
				modalIsOpen={createTeamModal}
				setModalIsOpen={setCreateTeamModal}
				loading={loading}
				error={createErr}
			/>
		</Modal>}
		{addToTeamsModal && <Modal style={{ maxWidth: 440, width: '90%' }} modalIsOpen={addToTeamsModal} setIsOpen={setAddToTeamsModal} >
			<AddToTeamsModal
				teams={teams}
				onConfirm={onAddToTeams}
				modalIsOpen={addToTeamsModal}
				setModalIsOpen={setAddToTeamsModal}
				loading={loading}
				error={createErr}
			/>
		</Modal>}

		<div className={cl.tabs}>
			<TabsPrime links={links} tabs={tabs} onSetTab={setActiveTab} />
		</div>

		<Modals ids={activeModal === 'reactivate' ? checkedDeactive : activeModal === 'revoke' ? checkedInvites : activeModal === 'resend' ? checkedInvites : []} actions={activeModal} onClose={onModalClose} onConfirm={onConfirm} />
	</>


	//mobile

	const [searchOpen, setSearchOpen] = useState(false)
	const [filterOpen, setFilterOpen] = useState(false)

	const closeSearchInp = () => {
		setSearchOpen(false)
		setSearchInput('')
	}

	return (
		winWidth > TABLET_WIDTH ?
			<div className='page__container'>
				<div className={cl.header}>
					<div className={cl.invite}>
						<HeaderPage style={{ margin: 0 }}>Users</HeaderPage>
						<Button left icon='plus' onClick={() => setInviteModal(true)} disabled={!verification}>Invite users</Button>
					</div>
					{/*<Button textbtn left icon='download'>Download as CSV</Button>*/}
				</div>
				{verification ? <><InputsRow
					searchInput={searchInput}
					onSearchChange={onSearchChange}
					setSearchInput={setSearchInput}
					onChangeSelectFilter={onChangeSelectFilter}
					selectOptions={options} />

					{commonComponents}
				</>
					: <AccessDenialOverlay />

				}

			</div>
			: <div className='page__container'>
				<div className={cl.header}>
					{searchOpen
						?
						<MobileSearchInputWithClose value={searchInput} setValue={setSearchInput} onClose={closeSearchInp} onSearchChange={onSearchChange} />
						: <>
							<div><BackButton title='Users' /></div><div className={cl.actions}>
								<i className={classNames('icon-plus', cl.invite__btn)} onClick={() => setInviteModal(true)} />
								<i className='icon-filter' onClick={() => setFilterOpen(true)} />
								<i className='icon-search' onClick={() => setSearchOpen(true)} />
							</div>
						</>
					}
				</div>
				{verification ? <>
					{commonComponents}
					<Sidebar sidebarIsOpen={filterOpen} setIsOpen={setFilterOpen} title='Filter' >
						<Filter options={mobOptions} value={multipleFilter} onChange={setMultipleFilter} setOpen={setFilterOpen} />
					</Sidebar>
				</>
					: <AccessDenialOverlay />
				}


			</div>
	)
}

export default UsersPage